import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@Redux';
import { GET_ORGANIZATION_TYPE } from '@Reducers/organization/actions';
import { ApplicationSettingsType } from '@Reducers/configuration/models';
import { getDataState } from '@Helpers/common/getDataState';
import { OrganizationTypes } from '@Constants/account';

export const useOrganizationType = () => {
	const dispatch: AppDispatch = useDispatch();
	const isUserLoggedIn = useSelector<RootState, boolean>(state => state.user.isUserLoggedIn);
	const organizationType = useSelector<RootState, OrganizationTypes>(state => state.organization.organizationType);
	const { nonDsbOrganizationGroups: nonDSBOrganizationGroups } = useSelector<RootState, ApplicationSettingsType>(
		state => state.configuration.applicationSettings
	);
	const { loading: organizationTypeLoading } = getDataState(organizationType);

	const nonDSBOrganization = nonDSBOrganizationGroups?.includes(organizationType);

	useEffect(() => {
		if (organizationTypeLoading && !organizationType && isUserLoggedIn) {
			dispatch(GET_ORGANIZATION_TYPE());
		}
	}, []);

	return {
		organizationType,
		organizationTypeLoading,
		technicalDataAvailable: !isUserLoggedIn || organizationType === OrganizationTypes.PUBLISHER,
		nonDSBOrganization,
	};
};
